.wrapModel {
  display: flex;
  flex-direction: column;
  padding: 18px;
}

.designamtion {
  color: green;
  position: relative;
  top: -12px;
  left: 0px;
  color: var(--typography-t-100, #697172);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  /* width: 160px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis */
}

.wrapModelContent {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.nameAlign {
  position: relative;
  color: var(--typography-t-300, #0b181a);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  left: 55px;
  bottom: 65px;
  /* width: 160px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis */
}
.alignDropValue {
  /* position: relative;
    left: 0px;
    top: 3px; */
  display: flex;
  width: 482px;
  height: 40px;
  padding: 6px 8px;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--surface-light, #f4f6f6);
}
.boxc {
  background-color: #0b181a;
}
.dropdownWrap {
  display: flex;
  /* background: var(--surface-light, red); */
}

.member {
  position: relative;
  top: -80px;
  left: 180px;
  color: var(--typography-t-200, #343f41);

  /* Caption/Caption 1 Semibold */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 140% */
  width: fit-content;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  /* gap: 2px; */
  border-radius: 12px;
  background: var(--surface-normal-active, #b3bfc1);
  /* line-height: 22px; */
}
.imageAlign {
  /* position: absolute;
  left: 19px; */
}
.wrapModel .MuiAutocomplete-root {
  width: auto;
  margin-top: 30px;
}
.modelContainer {
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Typography-T200, #343f41);
  background: var(--Sky-Light, #e7f6fd);
}

.heading {
  color: var(--typography-t-300, #0b181a);
  font-family: Inter !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 26px !important;
  margin-top: 10px;
}

.title {
  /* margin: 16px auto 24px; */
  color: var(--typography-t-200, #343f41);
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  margin: 0px;
}

.imageDiv {
  display: flex;
}

.mainDropDownSelected {
  display: flex;
  width: 100%;
  padding: 4px 8px;
  flex-direction: row;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--surface-light, #f4f6f6);
  margin: 8px;
  /* justify-content: end; */
}

.alignDropValueSelected {
  display: flex;
  position: relative !important;
  flex-direction: row;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  /* background: var(--surface-light, #f4f6f6); */
  position: relative;
  /* left: -31px; */
}

.nameAlignSelected {
  position: relative;
  display: flex;
  flex-direction: row;
  color: var(--typography-t-300, #0b181a);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
}

.designamtionSelected {
  display: flex;
  color: var(--typography-t-100, #697172);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 4px; */
  margin: 0px;
  display: inline-block;
  width: 104px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.memberSelected {
  display: flex;
  align-self: center;
  color: var(--typography-t-200, #343f41);
  cursor: pointer;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  width: fit-content;
  padding: 6px 0px;
  border-radius: 12px;
  background: var(--surface-normal-active, #b3bfc1);
  position: relative;
  left: 5px;
}

.memberSelectedOwner {
  display: flex;
  align-self: center;
  color: var(--typography-t-200, #343f41);
  cursor: pointer;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  width: fit-content;
  padding: 6px 0px;
  border-radius: 12px;
  position: relative;
  left: 5px;
  background: var(--Yellow-Normal, #eab308);
}

.imageCloseIcon {
  right: 6px;
  top: 6px;
  cursor: pointer;
}

.dropDownMemberSelection .MuiInputBase-root {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 12px;
  background: var(--surface-normal-active, #b3bfc1);
  color: var(--Typography-T200, #343f41);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  width: fit-content;
  height: 35px;
}

.memberSelected .MuiOutlinedInput-notchedOutline,
.memberSelectedOwner .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.memberSelected .MuiSelect-outlined,
.memberSelectedOwner .MuiSelect-outlined {
  font-size: 12px !important;
}

.memberSelected .MuiFormControl-root,
.memberSelected .MuiInputBase-root,
.memberSelectedOwner .MuiFormControl-root,
.memberSelectedOwner .MuiInputBase-root {
  height: 12px;
}

.itemCrossBtn {
  right: 15px;
  position: relative;
  top: 5px;
  left: -1px;
}

.nameRole {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.submitButton {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  border: 0.5px solid var(--Divider, #e2eaec);
  background: var(--Surface-White, #fff);
  padding: 16px 24px;
  margin-top: 100px;
  gap: 15px;
}

.btn {
  border-radius: 4px;
  border: 1px solid var(--Primary-Normal, #20444a);
}

.btnAlign:hover {
  border-radius: 4px;
  border: 1px solid var(--Primary-Normal, #20444a);
  background: var(--primary-light-hover, #dee3e4);
}

.btnAlign:hover {
  border-radius: 4px;
  border: 1px solid var(--Primary-Normal, #20444a);
  background: var(--primary-light-hover, #dee3e4);
}

.btn-create,
.btn-create:hover {
  border-radius: 4px;
  background: var(--Primary-Normal, #20444a);
  border: 0px;
  color: var(--Typography-T0, #fff);
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 0px 20px;
}

.btn-create-disabled,
.btn-create-disabled:hover {
  border-radius: 4px;
  background: var(--Typography-T50, #e7e8e8) !important;
  border: 0px;
  color: var(--Typography-T75, #9ba0a1) !important;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 0px 20px;
}

.fieldLable {
  color: #0b181a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.nameImageWrap {
  display: flex;
  position: relative;
  /* position: absolute; */

  /* left: 20px; */
  gap: 10px;
}

.modal.show .modal-dialog {
  transform: none;
  position: absolute;
  right: 10px;
  top: 50%;
}
.modal-title {
  color: var(--Grey-palette-Grey190, #201f1e);
  /* Body/Body Large Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
}

.modal-footer {
  justify-content: end;
}

.confirmButton {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  background: var(--Red-Normal, #ef4444);
  color: var(--Surface-White, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.confirmButton:hover {
  color: #fff;
  background: var(--Red-Normal, #ef4444);
}

.btnCancel {
  color: var(--Typography-T200, #343f41);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.wrapModel .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  display: flex;
  width: 158px;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
}
.bottomBtnCancel,
.bottomBtnCancel:hover {
  border-radius: 4px;
  border: 1px solid var(--Primary-Normal, #20444a);
  color: var(--Primary-Normal, #20444a);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0px;
}

.memberSelectedheight {
  width: 100%;
  height: 200px;
  margin-bottom: 50px;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
}

.selectedVlueDropDown {
  display: flex;
  justify-content: space-between;
  padding: 7px;
  background: var(--surface-light, #f4f6f6);
  margin: 2px;
}

.selectUsersDiv {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.selectUsers {
  width: 100% !important;
}

.initial-nameDropList {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
