.sideBarWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 310px;
  background: #183338;
  bottom: 0;
  transition: 1s ease;
  z-index: 1;
}

.sideBarLogo {
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(186, 197, 199, 0.15);
}
.sideBaritem {
  cursor: pointer;
}
.sideBaritemWrapper {
  padding: 16px 24px;
  display: flex;
  column-gap: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #b3bfc1;
  align-items: center;
  border-bottom: 1px solid rgba(186, 197, 199, 0.15);
  /* transition: 1s ease; */
}
.sideBaritemWrapper span {
  color: #b3bfc1;
}
.sideBaritemWrapper:hover,
.sideBaritemWrapper:hover span {
  background-color: #4d696e;
  color: #ffffff;
}
.sideBaritemWrapper.active,
.sideBaritemWrapper.active span {
  background-color: #20444a;
  color: #ffffff;
  font-weight: 600;
}
.sideBaritemWrapper.active svg,
.sideBaritemWrapper:hover svg {
  fill: #ffffff;
}
.addIcon {
  margin-left: auto;
}
.dropdown-content {
  padding: 0 15px 0 40px;
  position: relative;
  max-height: 200px;
  overflow-x: auto;
}

.dropdown-content::after {
  position: absolute;
  top: 0;
  left: 40px;
  width: 1px;
  background-color: #ffffff;
  content: "";
  bottom: 23px;
}
.dropdown-content ul {
  list-style: none;
  padding: 4px 0 0 12px;
}
.dropdown-content li {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px 24px;
  border-radius: 4px;
  color: #ffffff;
  position: relative;
}
.dropdown-content li:hover {
  background-color: #4d696e;
}
.dropdown-content li.Selected {
  background: #20444a;
  font-weight: 600;
}
.dropdown-content li:after {
  position: absolute;
  top: 0;
  left: -12px;
  border-bottom: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  content: "";
  width: 12px;
  height: 16px;
  border-radius: 0 0 0 8px;
}
.toggleIcon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bac5c7;
  border-radius: 50%;
  background: #20444a;
  position: absolute;
  right: -13px;
  top: 36px;
  z-index: 111;
}
.toggleIcon:hover {
  background: #4d696e;
}
.toggleIcon:focus {
  background: #173035;
}

.closeSideBar .sideBarWrapper {
  width: 104px;
}
.closeSideBar .sideBaritemWrapper {
  text-indent: -99rem;
  justify-content: center;
  /* font-size: 0; */
  column-gap: 0px;
}
.closeSideBar .sideBaritemWrapper .navItemIcon {
  text-indent: 0rem;
}
.closeSideBar .sideBaritemWrapper .addIcon {
  margin-left: 0;
  display: none;
}
.closeSideBar .dropdown-content {
  display: none;
}
.closeSideBar .toggleIcon {
  transform: rotate(182deg);
}

.sideBaritem a {
  text-decoration: none;
  width: 100%;
}

.minHeight {
  min-height: calc(100vh - 389px);
}

.setPosition {
  position: relative;
  height: 80%;
}

.hover-display {
  position: absolute;
  background-color: red;
  top: 100px;
  left: 20px;
  width: 200px;
  color: white;
}

.mouseHoverContentProjet {
  position: relative;
  top: -69px;
  left: 97px;
  display: flex;
  width: 250px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 12px;
  background: var(--Primary-Dark, #183338);
  box-shadow: 0px 15px 15px -1px rgba(0, 0, 0, 0.04),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 0.9px 4px -1px rgba(0, 0, 0, 0.08);
  margin-left: 10px;
}

.createProMouseHover {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Surface-White, #fff);
  color: var(--Primary-Normal, #20444a);
}

.createProMouseHover:hover {
  background-color: #dbe1e2 !important;
}

.mousehoverProjectList {
  width: 100%;
}

.mousehoverProjectList a {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0; */
  align-self: stretch;
  color: var(--Typography-T50, #e7e8e8);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
}

.mousehoverProjectList li {
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.mousehoverProjectList li:hover {
  background-color: #4d696e;
}

.mousehoverProjectList ul {
  display: flex;
  padding: 8px 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.mousehoverProjectList li.Selected {
  background: #20444a;
  font-weight: 600;
}

.setuserManagement {
  position: absolute;
  width: 100%;
  bottom: 5%;
}
.setuserManagement .sideBaritemWrapper {
  border-top: 1px solid rgba(186, 197, 199, 0.15);
}
.sideBarContainer {
  position: relative;
  height: 100vh;
  width: 100%;
}
