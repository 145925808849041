.drop-file-input {
  position: relative;
  border: 2px dashed var(--border-color);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--input-bg);
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input__label {
  color: var(--Typography-T300, #0b181a);
  /* Body/Body Large Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  text-align: center;
}

.drop-file-input__label img {
  width: auto;
  margin: 0 auto;
}

.drop-file-preview {
  margin-top: 30px;
  margin-bottom: 150px;
}

.drop-file-preview p {
  color: var(--Typography-T200, #343f41);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
  margin: 20px auto;
}

.drop-file-preview p.fileName {
  color: var(--Typography-T100, #697172);
  /* Caption/Caption */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px; /* 133.333% */
  margin: 0px;
}

.drop-file-preview p.size {
  color: var(--Typography-T100, #697172);
  /* Caption/Caption */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px; /* 133.333% */
  margin: 0px;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: var(--input-bg);
  padding: 8px;
  border-radius: 20px;
  background-color: #f4f6f6;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.drop-file-preview__item__del {
  background-color: var(--box-bg);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.orLine {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.fileContainer {
  border: 1px dashed #ccc;
  margin: 20px 0px;
  display: flex;
  max-height: 200px;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.uploadtitle {
  color: var(--Typography-T300, #0b181a);
  /* Subtitles/Subtitle */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  margin-top: 20px;
}
