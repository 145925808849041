.inputWrapper {
  display: flex;
  flex-direction: column;
}
.input {
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid var(--Divider, #e2eaec);
  background: var(--Surface-White, #fff);
  padding: 12px 12px 12px 12px;
  outline: 1px solid var(--Divider, #e2eaec);
}
/* input:focus {
  border: 1px solid var(--Divider, #e2eaec) !important;
} */
/* input:hover {
  border: 1px solid var(--Divider, #e2eaec) !important;
} */

.showIcon {
  margin-left: auto;
  position: relative;
  bottom: 33px;
  right: 12px;
}
