.mainWrapper {
    display: flex;
    overflow: hidden;
    background: var(--Surface-Light, #F4F6F6);
}
.contentWrapper {
    flex:1;
    margin-left: 310px;
    width: calc(100% - 310px);
    position: relative;
    min-height: 100vh;
    transition: 1s ease;
}
.closeSideBar .contentWrapper {
    margin-left: 104px;
    width: calc(100% - 104px);
}


