#customized-dialog-title {
  /* transform: none!important;
    position: absolute!important;
    right: 10px!important;
    top: 50%!important;
    width: 331px!important;
    height: 175px!important; */
}

.modalWrap .css-1t4vnk2-MuiDialogContent-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  /* flex: 1 1 auto; */
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 0px 24px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.12); */
}
