@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.body {
  font-family: Inter;
}

.MuiButtonBase-root {
  text-transform: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dbe1e2;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbe1e2;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dbe1e2;
}

.loginPageBtn {
  display: flex;
  justify-content: center;
}

.singleBtn {
  background-color: #20444a;
  padding: 20px 50px;
  margin: 150px auto;
  color: #fff;
  text-decoration: none;
}
