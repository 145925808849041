.main-wrapper {
  padding: 0 40px 20px;
  display: flex;
  flex-direction: column;
}
.breadcrumb-wrapper .breadcrumb {
  list-style: none;
  display: flex;
  column-gap: 8px;
  padding: 0;
}
.breadcrumb-wrapper .breadcrumb li {
  color: #697172;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.breadcrumb-wrapper .breadcrumb li a {
  cursor: pointer;
}
.breadcrumb-wrapper .breadcrumb li a {
  color: #20444a;
  text-decoration: none;
  font-weight: 600;
}

.inner-top-header {
  border-radius: 12px;
  border: 1px solid #e2eaec;
  background: #fff;
  padding: 8px 16px;
  margin-bottom: 16px;
  display: flex;
  width: 100%;
}
.inner-top-header-row {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.task-owner-button {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  column-gap: 14px;
  border-radius: 8px;
  background: #f4f6f6;
}
.owner-img {
  border-radius: 40px;
  border: 1px solid #e2eaec;
  background: #e2eaec;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
}

.owner-button .owner-main-button {
  padding: 4px 12px;
  border-radius: 12px;
  background: #20444a;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  border: 0;
}
.owner-content {
  display: flex;
  flex-direction: column;
  color: #343f41;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
span {
  color: #697172;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: block;
}

.on-fire-btn {
  border-radius: 12px;
  background: var(--red-normal-hover, #d73d3d);
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-color: transparent;
  color: white;
}

.reviwe-btn {
  min-width: 159px;
  padding: 8px 4px;
  color: white;
  border-radius: 4px;
  background: #20444a;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.section-row {
  display: flex;
  flex-direction: row;
  column-gap: 18px;
}
.section-left {
  flex: 1;
}

.content-wrpaaer h3 {
  color: #0b181a;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 6px;
}
.content-wrpaaer p {
  color: #697172;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  max-width: 750px;
  margin: 0 0 40px;
}

.tab-row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 16px;
}
.tile-column label {
  color: #0b181a;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 6px;
}
.tile-item {
  display: flex;
  align-items: center;
  min-width: 230px;
  padding: 8px 0px;
  gap: 8px;
  color: #343f41;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.content-wrpaaer h4 {
  color: #0b181a;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin: 30px 0 6px;
}
.tile-item.bg-color {
  padding: 16px 8px;
  border-radius: 8px;
  border: 0.5px solid rgba(186, 197, 199, 0.15);
  background: #fff;
}
.tile-title {
  color: #424b5a;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.tile-title span {
  color: rgba(66, 75, 90, 0.57);
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.Accordion-header-right-section {
  margin: 10px 5px;
}

.Accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Accordion-icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f6f6;
  transform: rotate(180deg);
}
.Accordion-icon svg {
  fill: #343f41 !important;
}
.accordion-header.Mui-expanded .Accordion-icon {
  background: #20444a;
  transform: rotate(0deg);
}
.accordion-header.Mui-expanded .Accordion-icon svg {
  fill: #f4f6f6 !important;
}
.accordion-wrapper {
  border-radius: 12px;
  background: #fff;
  margin: 0 !important;
  box-shadow: none !important;
  box-shadow: 0px 2.3px 2px 0px rgba(0, 0, 0, 0.03),
    0px 0.8px 0.8px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 8px !important;
  position: unset;
}
.accordion-wrapper::before {
  display: none !important;
}
.accordion-header {
  border-radius: 12px;
  background: #fff;
  min-height: 80px !important;
  box-shadow: 0px 2.3px 2px 0px rgba(0, 0, 0, 0.03),
    0px 0.8px 0.8px 0px rgba(0, 0, 0, 0.04);
}
.accordion-header h6 {
  width: 100%;
  margin: 0;
}
.accordion-header > div {
  margin: 0 !important;
}

.tile-column {
  cursor: pointer;
}

.leftSection {
  gap: 10px;
}

.label {
  border: 1px solid rgba(226, 234, 236, 1);
  background-color: #ffffff;
  padding: 12px 25px;
  border-radius: 15px;
  cursor: pointer;
}

.rightSection {
  display: flex;
  gap: 7px;
  align-items: center;
  flex-wrap: wrap;
}

.labelActive {
  background-color: rgba(32, 68, 74, 1);
  color: rgba(255, 255, 255, 1);
}

.spaceBetween {
  justify-content: space-between;
  flex-wrap: wrap;
}

.flexEnd {
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.none {
  display: none;
}

.video-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-popup-container {
  position: relative;
  /* background-color: #fff; */
  /* padding: 20px; */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-button {
  border-radius: 100%;
  position: absolute;
  top: -16px;
  right: -16px;
  cursor: pointer;
  color: #f4f6f6;
  background-color: #20444a;
  width: 17px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  height: 17px;
  z-index: 9;
}

.accordionContainer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.subTaskInsideTaskContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid var(--Divider-on-Dark, rgba(186, 197, 199, 0.15));
  background: var(--Surface-White, #fff);
}

.subTaskInsideTaskContainer .userDetail {
  display: flex;
  gap: 10px;
}

.subTaskInsideTaskContent {
  display: flex;
  margin: 10px 0px;
  color: var(--Typography-T100, #697172);
  /* Body/Body */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.timeDate {
  display: flex;
  gap: 8px;
  color: var(--Typography-T100, #697172);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.timeDateImage {
  position: relative;
  top: 7px;
  height: 24px;
  width: 24px;
}

.noteSection {
  margin-top: 30px;
}

.taskHeaderStatus .MuiOutlinedInput-notchedOutline {
  border: none;
}
.accordianDropdown .taskHeaderStatus {
  width: 110px;
}

.taskHeaderStatus {
  height: 28px !important;
}
.relative {
  position: relative;
}

.d-none {
  display: none;
}

.draw-arrow {
  margin-right: -10px;
  margin-top: 21px;
  background: #183338;
  border-radius: 100%;
  cursor: pointer;
}

.inner-top-header .MuiOutlinedInput-root {
  /* margin-top: 10px; */
  display: flex;
  padding: 2px 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  border-radius: 12px;
  height: 24px;
  /* background: var(--blue-light-hover, #e8eaee); */
  font-size: 12px;
}

.inner-top-header .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  /* color: white; */
}
.on-fire .statusDisplay {
  display: flex;
  line-height: 29px;
  gap: 5px;
}

.rightSection .priorityStatus {
  background-color: rgb(234, 179, 8);

  /* margin-top: 10px; */
  display: flex;
  padding: 2px 18px;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  border-radius: 12px;
  height: 24px;
  /* background: var(--blue-light-hover, #e8eaee); */
  font-size: 12px;
  pointer-events: none;
  color: white;
}

.priprities .MuiOutlinedInput-root {
  background-color: rgb(234, 179, 8);
  /* margin-top: 10px; */
  /* display: flex; */
  padding: 2px 8px;
  justify-content: flex-end;
  align-items: center;
  /* gap: 2px; */
  border-radius: 12px;
  height: 24px;
  /* background: var(--blue-light-hover, #e8eaee); */
  font-size: 10px;
}

.priprities .MuiOutlinedInput-notchedOutline {
  border: none;
}
.priprities .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  /* color: white; */
}

.accordianDropdown .wrapDropDowns {
  display: flex;
  gap: 5px;
}
.accordianDropdown
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-size: 12px;
}

.w-drawer {
  width: 480px;
}

.commonStatus {
  border: none;
  border-radius: 26px;
  font-size: 12px;
}

.white {
  color: var(--Blue-Dark, #000);
  background-color: var(--blue-light-hover, #fff);
}

.white .MuiSelect-iconOutlined {
  color: var(--Blue-Dark, #000);
}

.toDo {
  color: var(--Blue-Dark, #4b5768) !important;
  background-color: var(--blue-light-hover, #e8eaee) !important;
}

.toDo .MuiSelect-iconOutlined {
  color: var(--Blue-Dark, #4b5768);
}

.inProgress {
  color: var(--sky-dark-hover, #08638c) !important;
  background-color: var(--sky-light-hover, #dbf2fc) !important;
}

.inProgress .MuiSelect-iconOutlined {
  color: var(--sky-dark-hover, #08638c);
}

.onFire {
  color: var(--Typography-T0, #fff) !important;
  background-color: var(--red-normal-hover, #d73d3d) !important;
}

.onFire .MuiSelect-iconOutlined {
  color: var(--Typography-T0, #fff);
}

.revise {
  color: var(--red-dark-hover, #8f2929) !important;
  background-color: var(--red-light-hover, #fde3e3) !important;
}

.revise .MuiSelect-iconOutlined {
  color: var(--red-dark-hover, #8f2929);
}

.approvalPending {
  color: var(--yellow-dark-hover, #8c6b05) !important;
  background-color: var(--yellow-light-hover, #fcf4da) !important;
}

.approvalPending .MuiSelect-iconOutlined {
  color: var(--yellow-dark-hover, #8c6b05);
}

.approved {
  color: var(--lime-dark-hover, #4f7a0d) !important;
  background-color: var(--lime-light-hover, #edf7dc) !important;
}

.approved .MuiSelect-iconOutlined {
  color: var(--lime-dark-hover, #4f7a0d);
}

.todoIconAlign {
  display: flex;
  padding: 7px 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  border-radius: 26px;
  font-size: 12px;
}

.selectMenuStatus {
  font-size: 14px;
  padding: 6px;
  margin: 5px 5px;
}

.footerButton {
  display: flex;
  gap: 10px;
}

.dropDownPriority .MuiOutlinedInput-notchedOutline {
  border: none;
}

.approvalStatusUser {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.white {
  background-color: #fff;
}

.breadcrumb-wrapper .breadcrumb-item span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 600px;
  height: 1.2em;
  white-space: nowrap;
  margin-top: 3px;
}

.restrictContent {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 700px;
  height: 1.2em;
  white-space: nowrap;
  }

  .headerModal{
    padding: 12px 23px;
  }

  .modelButtonAlign {
    padding: 12px 23px;
    display: flex;
    gap: 16px;
  }
