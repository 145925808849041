.formWrapper {
  padding: 15px 25px 0;
  overflow: auto;
  height: 100vh;
}
.formWrapper h6 {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin: 14px 0 6px;
}
.formWrapper img {
  max-width: 100%;
  /* width: auto; */
}
.datePickerWrapper .textField {
  width: 100%;
  padding: 12px 12px 12px 12px;
  border-radius: 6px;
  border: 1px solid #e2eaec;
  background: #fff;
  box-sizing: border-box;
  margin-top: 3px;
}
.toot-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  margin-top: 4px;
}
.toot-item {
  border-radius: 8px;
  border: 0.5px solid rgba(186, 197, 199, 0.15);
  background: #fff;
  padding: 16px 8px;
  gap: 8px;
  display: flex;
  column-gap: 6px;
  width: calc(50% - 29px);
}
.toot-item.active {
  border: 0.5px solid #20444a;
}

label {
  display: flex;
  flex-direction: row;
}
.mark-as-assembly {
  margin: 10px 0;
}
.mark-as-assembly .labelItem {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 10px;
}
.mark-as-assembly .mark-as-assembly-wrapper {
  background: #e7f6fd;
  padding: 16px;
  color: #343f41;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  border: 1px solid #343f41;
  border-radius: 4px;
}
.mark-as-assembly .mark-as-assembly-wrapper span {
  width: 24px;
  height: 24px;
}
.CheckboxWrapper {
  display: flex;
  align-items: center;
}
.Checkboxlabel {
  color: #343f41;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
.forminnerWrapper {
  max-height: calc(100vh - 216px);
  overflow-y: auto;
}

.forminnerWrapper textarea {
  border-radius: 6px;
  border: 1px solid var(--Divider, #e2eaec);
  background: var(--Surface-White, #fff);
  /* overflow: hidden; */
  /* display: flex; */
  /* width: 482px; */
  height: 143px;
  width: 98%;
}

.formWrapper .react-datepicker-wrapper {
  width: 100%;
}

.textControl .MuiInputBase-root {
  width: 100%;
}
.textControl {
  margin-bottom: 15px;
}

.forminnerWrapper textarea::placeholder {
  color: var(--Navigation-Input-Placeholder, #737373);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  color: #737373;
  padding-top: 5px;
}

.CheckboxWrapper .MuiCheckbox-root {
  /* color: #20444A; */
}
.CheckboxWrapper input[type="checkbox"]:checked {
  background-color: #20444a;
}

/* .CheckboxWrapper .MuiButtonBase-root{
  color: #20444A;
} */

.CheckboxWrapper .MuiButtonBase-root {
  color: #20444a;
}

.CheckboxWrapper .MuiSvgIcon-root {
  color: #20444a;
}
