.offcanvas-body {
  padding: 0px;
}

.modalHeaderWrapper {
  display: flex;
  padding: 24px 24px 0px 25px;
  justify-content: space-between;
}

.modalheader {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.cross {
  cursor: pointer;
}