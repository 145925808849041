.mainButton {
  display: flex;
  height: 48px;
  padding: 14px 30px;
  align-items: center;
  border-radius: 4px;
  background: #20444a;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid #20444a;
  column-gap: 10px;
  cursor: pointer;
}

.disabledButton {
  display: flex;
  height: 48px;
  padding: 14px 30px;
  align-items: center;
  border-radius: 4px;

  color: var(--Typography-T75, #9ba0a1);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background: var(--Typography-T50, #e7e8e8);
  border: none;

  column-gap: 10px;
  cursor: pointer;
}
.mainButton:hover {
  background: #4d696e;
  border-color: #4d696e;
}
.mainButton:focus {
  background: #173035;
  border-color: #173035;
}

.outlineButton {
  display: flex;
  height: 48px;
  padding: 14px 30px;
  align-items: center;
  border-radius: 4px;
  background: #ffff;
  color: #20444a;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid #20444a;
  column-gap: 10px;
  cursor: pointer;
}

.outlineButton:hover,
.outlinelessButton:hover {
  background-color: #dee3e4;
}

.outlineButton:focus,
.outlinelessButton:hover {
  background: #bac5c7;
}

.outlinelessButton {
  display: flex;
  height: 48px;
  /* padding: 14px 30px; */
  align-items: center;
  border-radius: 4px;
  background: #ffff;
  color: #20444a;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: 0;
  column-gap: 10px;
  cursor: pointer;
}

.outlinelessButtonDisbale {
  background-color: rgba(231, 232, 232, 1);
  border: none;
}
