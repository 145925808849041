.button {
  color: #20444a !important;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #20444a;
  column-gap: 8px;
  cursor: pointer;
}
.primaryColour {
  display: flex;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 4px;
    border: 1px solid #20444a;
    cursor: pointer;
    border-radius: 4px;
    background: var(--Primary-Normal, #20444a);
    color: #ffffff;
}
