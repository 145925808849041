.wrap {
  background-color: #f4f6f6;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  height: auto;
  padding: 25px 85px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 2.3px 2px 0px rgba(0, 0, 0, 0.03),
    0px 0.8px 0.8px 0px rgba(0, 0, 0, 0.04);
}

.innerContainer {
  width: 100%;
  justify-content: center;
}

.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  margin-top: 15px;
}

.image span {
  color: #4d696e;
  text-align: center;
  font-family: Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.email {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
}

.email .form-label {
  color: #0b181a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.email .form-control {
  color: #697172;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
}

.password .form-label {
  color: #0b181a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.password .form-control {
  color: #697172;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container-element {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  color: #697172;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  margin-top: 15px;
}

.grid-child-element {
  display: flex;
  gap: 14px;
  font-size: 14px;
  color: var(--Typography-T100, #697172);
  margin: 0px 10px;
  cursor: pointer;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  width: 22px;
  height: 22px;
}

.defaultBtn {
  width: 100%;
  display: flex;
  padding: 14px 30px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #20444a !important;
  margin: 0 auto;
  margin-top: 16px;
  border: none;
  border-radius: 4px;
  background-color: #20444a !important;
  color: #ffff !important;
  text-align: center;
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;
  text-transform: none !important;
}

.defaultBtn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #ffff;
  background-color: #20444a;
  border-color: none;
}

.defaultBtn:hover {
  color: #fff;
  text-align: center;
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
}

.form-control:focus {
  border-radius: 6px;
  border: 1px solid #20444a;
  background: #fff;
  box-shadow: 0px 0px 3px 0px #20444a;
}

.margin-t-30 {
  margin-top: 30px;
}

.margin-t-0 {
  margin-top: 0px;
}

.margin-b-30 {
  margin-bottom: 30px;
}
.divLoginBtn {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
}
.errorMessage {
  display: flex;
  width: 100%;
  margin: 10px 0px;
}
/* .MuiAlert-root {
  background: none;
  color: red;
}
.MuiAlert-icon {
  display: none;
} */

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.displayNone {
  display: none;
}

input[type="checkbox"]:checked {
  background-color: #20444a; /* Set the background color for checked state */
}

.loginCaption {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.loginCaptionHeading {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.loginCaptionMessage {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.loginContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginBackToLogin {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.errorMessage .MuiAlert-standardError {
  width: 100% !important;
}
