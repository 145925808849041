.headerWrapper {
  padding: 0 40px;
  border-bottom: 1px solid #bac5c7;
}
.headerContainer {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: flex-end;
}
.headerCompanyName {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.headerRightSection {
  display: flex;
  /* column-gap: 12px; */
  align-items: center;
  justify-content: space-between;
}
.notifiactionIcon {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
}
.userProfile {
  display: flex;
  align-items: center;
}
.userProfile {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.userProfile .userProfileImages {
  display: flex;
  width: 52px;
  height: 52px;
  border-radius: 52px;
  border: 1px solid #9ba0a1;
  overflow: hidden;
}
.userProfile .userProfileImages img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.userProfileText {
  display: flex;
  flex-direction: column;
  color: #0b181a;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.userProfileText span {
  color: #6a7273;
  display: block;
  font-weight: 400;
}

.headerContainer .initial-name {
  width: 50px;
  height: 50px;
  background-color: #ccc;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dropDownContainer {
  display: none;
}

.dropDownContainer li {
  list-style-type: none;
}

.dropDownContainer ul {
  padding: 0px;
}

.userProfileHeaderItem:hover .dropDownContainer {
  display: flex;
  z-index: 11;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  flex-direction: column;
  background-color: #183338;
  color: #ffffff;
  padding: 10px;
  position: absolute;
  left: 0;
  top: 100px;
  border-radius: 0px 0px 10px 10px;
  cursor: pointer;
}

.userProfileHeaderItem {
  position: relative;
  cursor: pointer;
  padding: 25px 0px;
}
