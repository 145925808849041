.Notes_wrapper {
  border-radius: 12px;
  border-radius: 12px;
  border: 1px solid var(--Divider, #e2eaec);
  background: var(--Surface-White, #fff);
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  flex-shrink: 0;
  /* height: 100%; */
  height: 500px;
}

.Notes_wrapper .css-1kcggdq-MuiInputBase-root .MuiInputBase-input {
  color: var(--Typography-T100, #697172);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 15px;
}

.Notes_wrapper .css-96uuyl {
  margin-left: 14px;
  width: auto;
  height: 47.248px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--Divider, #e2eaec);
  background: var(--Surface-White, #fff);
}

.notes_text {
  color: var(--Primary-DarkFont, #2c2e31);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
  padding: 15px;
  margin: 0px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notes_text svg {
  cursor: pointer;
}

.AllImageText {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Typography-T200, #343f41);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.Notes_wrapper .images {
  cursor: pointer;
}

.Notes_wrapper .imagesWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.Notes_wrapper .alignDropValueSelected {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  position: relative;
}

.Notes_wrapper .nameRole {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Notes_wrapper .nameAlignSelected {
  display: flex;
  flex-direction: row;
  color: var(--typography-t-300, #0b181a);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.Notes_wrapper .designamtionSelected {
  display: flex;
  color: var(--typography-t-100, #697172);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 4px; */
  margin: 0px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.Notes_wrapper .no-overflow.designamtionSelected {
  overflow: unset !important;
}

.Notes_wrapper .wrapBox {
  display: flex;
  flex-direction: column;
  margin: 10px 6px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--Divider-on-Dark, rgba(186, 197, 199, 0.15));
  background: var(--Surface-White, #fff);
  padding: 10px;
}

.Notes_wrapper .boxNotes {
  color: var(--Typography-T100, #697172);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 9px;
}

.Notes_wrapper .timeDate {
  display: flex;
  gap: 8px;
  color: var(--Typography-T100, #697172);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.Notes_wrapper .timeDateImage {
  position: relative;
  top: 7px;
  height: 24px;
  width: 24px;
}
.Notes_wrapper .nameImageWrap {
  display: flex;
  left: 10px;
  gap: 10px;
}

.noteSubmit {
  display: flex;
  width: 100%;
  /* border-radius: 12px 12px 0px 0px;
  border: 0.5px solid var(--Divider, #e2eaec);
  background: var(--Surface-White, #fff); */
  padding: 16px 0px;
  gap: 10px;
  padding-left: 0px;
  /* margin-top: 100px; */
}

.wrapTextAdd {
  width: 100%;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--Divider-on-Dark, rgba(186, 197, 199, 0.15));
  background: var(--Surface-White, #fff);
  display: grid;

  /* Card Shadow */
  /* box-shadow: 0px 2.3px 2px 0px rgba(0, 0, 0, 0.03),
    0px 0.8px 0.8px 0px rgba(0, 0, 0, 0.04); */
}
.textArea {
  /* width: 100%; */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  color: #000000;
  height: 47.248px !important;
  border-radius: 12px;
  border: 1px solid var(--Divider, #e2eaec);
  background: var(--Surface-White, #fff);
}

/* Remove focus outline for all textareas */
textarea:focus {
  outline: none;
}

/* If you want to keep focus styles for keyboard navigation but remove for mouse users */
textarea:focus:not(:focus-visible) {
  outline: none;
}

textarea {
  resize: none;
}

.btnAddNote {
  color: var(--Surface-White, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.notesItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.Notes_wrapper .initial-name {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.col1Grid {
  border-right: 1px solid #ccc;
  padding: 4px 0px;
}

.noteContent {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.noteData {
  max-height: 420px;
  overflow-x: auto;
  padding: 5px;
}
.bottomBtnCancel:hover {
  background: var(--primary-light-hover, #dee3e4);
}

.btnAddNote:hover {
  background: var(--primary-normal-hover, #4d696e);
}
